<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Business Logs</h1>
      <!-- <div class="bg-blue-100 p-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div> -->
      <!-- <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    bg-blue-900
                    text-white
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-8
                    rounded
                    
                  "
        >
          Download CSV
        </button>
      </div> -->
      <div class="mt-5 mb-20 flex justify-start items-start bg-blue-50">
        <div class="flex">
          <div class="inline-block bg-blue-50 p-5">
            <p class="font-bold">Total Businesses</p>
            <p>{{ numberOfTotalBusinesses }} Businesses</p>
            <button
              @click="downloadTotalBusinesses"
              class="block uppercase shadow mt-2 text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none hover:text-white bg-blue-900 text-white p-2 rounded"
            >
              Download
            </button>
          </div>
        </div>
        <div class="flex mx-5">
          <div class="inline-block bg-blue-50 p-5">
            <p class="font-bold">New Signups (Last 7 days)</p>
            <p>{{ numberOfItems7days }} businesses</p>
            <button
              @click="downloadLast7DaysBusinesses"
              class="block uppercase shadow mt-2 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs bg-blue-900 text-white p-2 rounded"
            >
              Download
            </button>
          </div>
        </div>
        <div class="flex mx-5">
          <div class="inline-block bg-blue-50 p-5">
            <p class="font-bold">New Signups (Last 30 days)</p>
            <p>{{ numberOfItems30days }} businesses</p>
            <button
              @click="downloadLast30DaysBusinesses"
              class="block uppercase shadow mt-2 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs bg-blue-900 text-white p-2 rounded"
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearFilter"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="flex bg-blue-50 p-5">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startdatetime"
          />
          <input
            type="time"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startTime"
          />
        </div>

        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.enddatetime"
          />
          <input
            type="time"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endTime"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedFilteredRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
          <button
            @click="downloadDatedCSV"
            class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Download
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="18"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business ID
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              User ID
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Category
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email Address
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Phone Number
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Pricing group
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Loyalty Active
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Sell By Online
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Sell By Invoice
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Sell By POS
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Street
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              City
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              State
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Country
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Postal code
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Suspended
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date created
            </th>
          </tr>
          <tr v-for="(account, i) in filterAccounts" :key="i">
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{
                (account._id && account._id) ||
                (account.business_id && account.business_id) ||
                "N/A"
              }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.user_id || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.name || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.category || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.email || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.phone || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.pricing_group || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.loyalty_active || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.business_use_case.sell_by_online || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.business_use_case.sell_by_invoice || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.business_use_case.sell_by_pos || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.address.street || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.address.city || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.address.state || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.address.country || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.address.postal_code || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.suspended || "N/A" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{
                moment(account.created_at).format("DD MMM YYYY - h:mm:ss a") ||
                "N/A"
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="GET_TOTAL_BUSINESSES.data.items.length > 99"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          Please check your email in 5 mins for the download link.
          <br />Please note that it will take longer to generate a download link
          for larger datasets.
          <button
            @click="alertDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      dateData: null,
      currentPage: 1,
      totalPages: 0,
      numberOfItems7days: 0,
      numberOfItems30days: 0,
      numberOfTotalBusinesses: 0,
      alertDiv: false,
      isDisabled: true,
      dateFilter: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_TOTAL_BUSINESSES"]),
    filterAccounts() {
      return (
        this.GET_TOTAL_BUSINESSES.data.items &&
        this.GET_TOTAL_BUSINESSES.data.items.filter(
          (account) =>
            (account._id &&
              account._id.toLowerCase().includes(this.search.toLowerCase())) ||
            (account.business_id &&
              account.business_id
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (account.user_id &&
              account.user_id
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (account.name &&
              account.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (account.email &&
              account.email
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (account.phone &&
              account.phone.toLowerCase().includes(this.search.toLowerCase()))
        )
      );

      //   if (this.statusSort !== "all") {
      //     return [...k].filter((x) => {
      //       return x.is_fraud === this.statusSort;
      //     });
      //   } else {
      //     return k;
      //   }
      // return k;
    },
    // all() {
    //   return this.GET_FRAUD.data.items;
    // },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    const d = new Date();

    this.dateData = {
      //   startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };
    this.fetchData();
  },
  methods: {
    ...mapActions([
      "FETCH_TOTAL_BUSINESSES",
      "FETCH_COUNTER_FOR_BUSINESS_LOGS",
      "DOWNLOAD_DATE_FILTERED_BUSINESS_LOG_CSV",
      "FETCH_PAGINATED_TOTAL_BUSINESSES",
      "SEARCH_TOTAL_BUSINESSES",
      "FETCH_DATE_FILTERED_BUSINESS_LOG",
      "DOWNLOAD_ALLBUSINESSES_LOGS_CSV",
      "DOWNLOAD_COUNTER_FOR_BUSINESS_LOGS_CSV",
    ]),
    async fetchData() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch("FETCH_TOTAL_BUSINESSES");
        if (response.status) {
          this.numberOfTotalBusinesses = response.data.totalCount;
        } else {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}

      try {
        let response = await this.$store.dispatch(
          "FETCH_COUNTER_FOR_BUSINESS_LOGS",
          { days: 7 }
        );
        if (response.status) {
          this.numberOfItems7days = response.data.lastSignUp;
        } else {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}

      try {
        let response = await this.$store.dispatch(
          "FETCH_COUNTER_FOR_BUSINESS_LOGS",
          { days: 30 }
        );
        if (response.status) {
          this.isLoading = false;
          this.numberOfItems30days = response.data.lastSignUp;
        } else {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async downloadDatedCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_DATE_FILTERED_BUSINESS_LOG_CSV",
          { date: this.dateData }
        );

        if (!res.status) {
          alert("Could not find any data within the date range");
          this.$router.go();
        } else {
          // console.log(res.data);
          this.isLoading = false;
          this.alertDiv = true;
          console.log(res.status);
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_TOTAL_BUSINESSES",
          {
            page: currPage - 1,
            date: this.dateData,
            dateFilter: this.dateFilter,
          }
        );
        if (res.status) {
          if (this.GET_TOTAL_BUSINESSES.data.items === null) {
            this.GET_TOTAL_BUSINESSES.data.items = [];
          }
          this.isLoading = false;
          this.currentPage -= 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_TOTAL_BUSINESSES",
          {
            page: currPage + 1,
            date: this.dateData,
            dateFiltered: this.dateFilter,
          }
        );
        if (res.status) {
          if (this.GET_TOTAL_BUSINESSES.data.items === null) {
            this.GET_TOTAL_BUSINESSES.data.items = [];
          }
          this.isLoading = false;
          this.currentPage += 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async handleSearch() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "SEARCH_TOTAL_BUSINESSES",
        this.search
      );
      this.isLoading = false;

      if (!res.status) {
        // this.isLoading = false;
        alert("Could not find " + this.search);
        // this.isLoading = false;
        this.fetchData();
      }
    },
    async fetchDatedFilteredRecords() {
      // console.log(this.dateData.startdatetime > this.dateData.enddatetime);
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        this.dateFilter = true;
        this.currentPage = 1;
        try {
          let res = await this.$store.dispatch(
            "FETCH_DATE_FILTERED_BUSINESS_LOG",
            { date: this.dateData }
          );

          if (res.data.item === null) {
            alert("Could not find any data within the date range");
            this.$router.go();
          } else {
            // console.log(res.data);
          }
        } catch (error) {}
      }

      this.isLoading = false;
      // console.log("Testing");
    },

    async downloadTotalBusinesses() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_ALLBUSINESSES_LOGS_CSV");

        if (res.status) {
          //   window.open(res.data, "_blank");
          this.alertDiv = true;
        } else {
          alert("Could not find " + this.search);
          // console.log(res.data);
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async downloadLast30DaysBusinesses() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_COUNTER_FOR_BUSINESS_LOGS_CSV",
          { days: 30 }
        );

        if (res.status) {
          window.open(res.data, "_blank");
        } else {
          alert("Could not find " + this.search);
          // console.log(res.data);
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async downloadLast7DaysBusinesses() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_COUNTER_FOR_BUSINESS_LOGS_CSV",
          { days: 7 }
        );

        if (res.status) {
          window.open(res.data, "_blank");
        } else {
          alert("Could not find " + this.search);
          // console.log(res.data);
        }
      } catch (error) {}
      this.isLoading = false;
    },
    clearFilter() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
